import { Logger } from '@frontend/Logger';

import { AndroidConnectionDriver } from './android/android-connection-driver';
import AndroidHardwareAPI from './android/android-hardware-api';
import { HardwareAPI, HardwareAPIType, HardwareConnection } from './hardware-connection';
import { UnknownConnectionDriver } from './unknown/unknown-connection-driver';
import { WindowsConnectionDriver } from './windows/windows-connection-driver';
import WindowsHardwareAPI from './windows/windows-hardware-api';

export class HardwareConnectionManager {
    private static instance: HardwareConnectionManager | null;
    private static windowsCommsObject: any | null;
    static hardwareAPIInterface: HardwareAPI;
    static hardwareAPIType: HardwareAPIType;

    private connection!: HardwareConnection;

    private constructor() {
        HardwareConnectionManager.getWindowsCommsObject();
        HardwareConnectionManager.initiateConnection();
        this.setConnection();
    }

    static getInstance() {
        if (!HardwareConnectionManager.instance) {
            HardwareConnectionManager.instance = new HardwareConnectionManager();
        }
        return HardwareConnectionManager.instance;
    }

    private setConnection(): void {
        switch (HardwareConnectionManager.hardwareAPIType) {
            case HardwareAPIType.ANDROID:
                this.connection = new AndroidConnectionDriver(HardwareConnectionManager.hardwareAPIInterface as AndroidHardwareAPI);
                break;
            case HardwareAPIType.WINDOWS:
                this.connection = new WindowsConnectionDriver(HardwareConnectionManager.hardwareAPIInterface as WindowsHardwareAPI);
                break;
            case HardwareAPIType.WEBSOCKET:
            case HardwareAPIType.UNKNOWN:
                Logger.warn('No hardware connection found. So using fallback mock interface.');
                this.connection = new UnknownConnectionDriver(HardwareConnectionManager.hardwareAPIInterface);
        }
    }

    getConnection(): HardwareConnection {
        if (!this.connection) {
            this.setConnection();
        }
        return this.connection;
    }

    private static initiateConnection() {
        if (HardwareConnectionManager.windowsCommsObject !== null) {
            HardwareConnectionManager.hardwareAPIInterface = HardwareConnectionManager.windowsCommsObject.TerminalApp;
            HardwareConnectionManager.hardwareAPIType = HardwareAPIType.WINDOWS;
        } else if (window.TerminalApp) {
            HardwareConnectionManager.hardwareAPIInterface = window.TerminalApp;
            HardwareConnectionManager.hardwareAPIType = HardwareAPIType.ANDROID;
        } else {
            HardwareConnectionManager.hardwareAPIType = HardwareAPIType.UNKNOWN;
        }
    }

    private static getWindowsCommsObject() {
        try {
            HardwareConnectionManager.windowsCommsObject = chrome.webview.hostObjects;
        } catch {
            HardwareConnectionManager.windowsCommsObject = null;
        }
    }
}
