import { Logger } from '@frontend/Logger';

import { HardwareConnection } from '../hardware-connection';
import { TerminalEvents } from '../hardware-events';

export enum AndroidEvents {
    INIT = 'terminal:initResult',
    KEEP_ALIVE = 'terminal:keepAliveResult',
    INFO_SLOT = 'terminal:getInfoSlotResult',
    OCCUPY_SLOT = 'terminal:getOccupySlotResult',
    SCANNER = 'terminal:scannerResult',
    SCANNER_QR = 'terminal:scannerResultQR',
    SCANNER_RFID = 'terminal:scannerResultRFID',
    OPEN_SLOT = 'terminal:openSlotResult',
    PROTON = 'terminal:proton',
    SLOT_STATE = 'terminal:slotsStateUpdateResult',
    OAUTH_RESPONSE = 'terminal:externalOAuthResponse'
}

export class AndroidEventListener {
    connection: HardwareConnection;

    constructor(connection: HardwareConnection) {
        this.connection = connection;
        Object.values(AndroidEvents).forEach((value) => {
            window.addEventListener(value, (ev) => this.eventHandler(ev, value));
        });
    }

    private eventHandler = (event: CustomEvent, eventName: AndroidEvents): void => {
        Logger.debug(event, {}, eventName);
        switch (event.type) {
            case AndroidEvents.KEEP_ALIVE:
                Logger.debug('Keep alive result received from android.');
                break;
            case AndroidEvents.INIT:
                Logger.log('Android init response received.', {}, event.detail);
                localStorage.setItem('android', event.detail.appVersion);
                window.dispatchEvent(
                    new CustomEvent(TerminalEvents.HARDWARE_ID_RESULT, {
                        detail: {
                            hardwareId: event.detail.androidId
                        }
                    })
                );
                //Not relevant for now but usefull in the future
                if (+event.detail.appVersion < 4.1)
                    Logger.warn(
                        'The android version installed on this device is outdated and should be updated as soon as possible to avoid unexpected behaviour.'
                    );
                break;
            case AndroidEvents.SCANNER_RFID:
            case AndroidEvents.SCANNER_QR:
            case AndroidEvents.SCANNER:
                window.dispatchEvent(
                    new CustomEvent(TerminalEvents.SCANNER_RESULT, {
                        detail: event.detail.scannerValue
                            .trim()
                            .replace(/[^\x20-\x7E]/g, '')
                            .replace(/\uFFFD/g, '')
                    })
                );
                break;
            case AndroidEvents.PROTON:
                Logger.log(`Proton event \"${JSON.parse(event.detail.proton).header.action}\" received.`, {}, JSON.parse(event.detail.proton));
                window.dispatchEvent(new CustomEvent(TerminalEvents.PROTON, { detail: JSON.parse(event.detail.proton) }));
                break;
            default:
                Logger.warn('Unknown message received from android.', {}, event);
                break;
        }
    };
}
