import { useEffect, useState } from 'react';

import { endTransaction, fetchTransactionSlots } from '../../../redux/pin-workflow-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { OpenSlotViewProps } from './open-slot-view.component';

interface ViewProps {
    state: OpenSlotViewState;
}

export enum OpenSlotViewState {
    INIT = 'init',
    LOADING = 'loading',
    CONFIRM_SLOT = 'confirm_slot',
    OPENING_SLOT = 'opening_slot',
    CHANGE_SLOT = 'change_slot',
    SLOT_OPENED = 'slot_opened',
    SLOT_CLOSED = 'slot_closed'
}

const useOpenSlotView = (props: OpenSlotViewProps): ViewProps => {
    const dispatch = useAppDispatch();
    const pinWorkflow = useAppSelector((state) => state.pinWorkflow);
    const [state, changeState] = useState<OpenSlotViewState>(OpenSlotViewState.INIT);

    useEffect(() => {
        if (pinWorkflow.slots) {
            if (pinWorkflow.slotsConfirmed === null) {
                changeState(OpenSlotViewState.CONFIRM_SLOT);
            } else if (pinWorkflow.slotsConfirmed === true) {
                changeState(OpenSlotViewState.OPENING_SLOT);
                //Currently set timeout to mock the slot acutlly opening
                setTimeout(() => {
                    changeState(OpenSlotViewState.SLOT_OPENED);
                    setTimeout(() => {
                        changeState(OpenSlotViewState.SLOT_CLOSED);
                        dispatch(endTransaction(pinWorkflow.pinCodes![0]));
                    }, 2000);
                }, 2000);
            } else {
                changeState(OpenSlotViewState.CHANGE_SLOT);
            }
            return;
        }
        if (state === OpenSlotViewState.INIT) {
            dispatch(fetchTransactionSlots(pinWorkflow.pinCodes![0]));
            changeState(OpenSlotViewState.LOADING);
        }
    }, [pinWorkflow]);

    return { state };
};

export default useOpenSlotView;
