import { Workflow } from '@frontend/workflow';

import useWorkflowPage from './workflow-page.controller';

const ID = 'workflow-page';
export interface WorkflowPageProps {
    workflow: Workflow;
}

/**
 * The workflow page should get the unfinished steps within the workflow
 * go over them one by one
 *      -> of course create a new element that takes the info of a step and returns the corresponding UI
 * make sure it is clear to the user what workflow he is in
 *
 */
const WorkflowPage = (props: WorkflowPageProps) => {
    const viewProps = useWorkflowPage(props);

    return (
        <div id={ID}>
            <h4 className='text-center'>{props.workflow.name}</h4>
        </div>
    );
};

export default WorkflowPage;
