import { accountSlice } from '@frontend/account';
import { workflowButtonSlice, workflowSharedSlice, workflowSlice, workflowStepSlice } from '@frontend/workflow';
import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { navigationSlice } from './navigation-slice';
import { pinWorkflowSlice } from './pin-workflow-slice';

export const store = configureStore({
    reducer: {
        accounts: accountSlice.reducer,
        workflows: workflowSlice.reducer,
        workflowSteps: workflowStepSlice.reducer,
        workflowButtons: workflowButtonSlice.reducer,
        workflowShared: workflowSharedSlice.reducer,

        //terminal states
        pinWorkflow: pinWorkflowSlice.reducer,
        navigation: navigationSlice.reducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
