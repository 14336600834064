import { Logger } from '@frontend/Logger';

import BasicAuthenticationClient from './basic-authentication-client';
import { AuthenticationSuccessResponse } from './models';

export class AuthenticationManager {
    private static instance: AuthenticationManager | null;
    private blocked = false;
    public loggedIn: boolean;

    private constructor() {
        this.loggedIn = this.hasValidToken();
    }

    public static getInstance = (): AuthenticationManager => {
        if (!AuthenticationManager.instance) {
            AuthenticationManager.instance = new AuthenticationManager();
        }
        return AuthenticationManager.instance;
    };

    async authenticate(email: string, password: string): Promise<AuthenticationSuccessResponse> {
        if (this.blocked) Logger.debug('Authentication manager is already being used by another process.');
        this.blocked = true;
        const credentials = await BasicAuthenticationClient.login(email, password);

        this.saveTokenToStorage(credentials);
        Logger.log(credentials);
        this.blocked = false;
        this.loggedIn = true;
        return credentials;
    }

    async refresh(): Promise<void> {
        if (this.blocked) Logger.debug('Authentication manager is already being used by another process.');
        this.blocked = true;

        try {
            const credentials = await BasicAuthenticationClient.refresh(this.getTokenFromStorage().refresh_token);
            this.saveTokenToStorage(credentials);
            this.loggedIn = true;
        } catch (error) {
            Logger.error('Something went wrong trying to refresh the token. Logging out.', {}, error);
            this.logout();
        } finally {
            this.blocked = false;
        }
    }

    async getTokenWhenReady(): Promise<AuthenticationSuccessResponse> {
        return new Promise((resolve, reject) => {
            if (!this.hasValidToken() && this.blocked === false) reject();
            if (this.blocked) {
                const interval = setInterval(() => {
                    if (this.blocked === false) {
                        resolve(this.getTokenFromStorage());
                        clearInterval(interval);
                    }
                }, 1000);
            } else resolve(this.getTokenFromStorage());
        });
    }

    async logout(callback?: () => any): Promise<void> {
        localStorage.removeItem('token');
        this.loggedIn = false;
        callback && callback();
    }

    //TODO does not actually check validity yet
    hasValidToken(): boolean {
        try {
            const credentials = this.getTokenFromStorage();
            return !!credentials.jwt_token;
        } catch (e) {
            return false;
        }
    }

    private getTokenFromStorage(): AuthenticationSuccessResponse {
        const token = localStorage.getItem('token');
        if (token == null) {
            Logger.error('Something went wrong trying te get the access token from localstorage.');
            throw new Error('No token information available.');
        }
        return JSON.parse(token) as AuthenticationSuccessResponse;
    }
    private saveTokenToStorage(token: AuthenticationSuccessResponse): void {
        localStorage.setItem('token', JSON.stringify(token));
    }
}
