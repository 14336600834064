import { confirmSlots } from '../../../../redux/pin-workflow-slice';
import { useAppDispatch } from '../../../../redux/store';
import { ConfirmSlotViewProps } from './confirm-slot-view.component';

interface ViewProps {
    onSubmit: () => void;
}

const useConfirmSlotView = (props: ConfirmSlotViewProps): ViewProps => {
    const dispatch = useAppDispatch();

    const onSubmit = () => {
        dispatch(confirmSlots());
    };

    return { onSubmit };
};

export default useConfirmSlotView;
