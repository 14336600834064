import { WorkflowButtonType } from '@frontend/workflow';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface NavigationState {
    view: WorkflowButtonType | null;
}

const initialState: NavigationState = {
    view: null
};

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        changeView: (state, action: PayloadAction<WorkflowButtonType | null>) => {
            state.view = action.payload;
        }
    }
});

export const { changeView } = navigationSlice.actions;
