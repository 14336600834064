import { APIClient } from '@frontend/api-utils';

import { Transaction } from '../transaction';
import { TransactionStateName } from '../transaction-state';

const endpoint = '/transaction-service/api/v1';

export class TransactionWorkflowClient extends APIClient {
    public static async updateSpotTransactionState(spotId: string, transactionId: string, state: TransactionStateName): Promise<Transaction> {
        const response = await this.patch(`${endpoint}/spots/${spotId}/transactions/${transactionId}/change-state`, { state: state });
        return await this.handleResponse<Transaction>(response);
    }

    public static async postMoveTransaction(spotId: string, transactionId: string, newSpotId: string): Promise<Transaction> {
        const response = await this.post(`${endpoint}/spots/${spotId}/transactions/${transactionId}/change-state`, { spot_id: newSpotId });
        return await this.handleResponse<Transaction>(response);
    }
}
