import { Logger } from '@frontend/Logger';
import { Spinner } from '@frontend/elements';
import { PinCodeType } from '@frontend/transaction';
import { WorkflowButtonType } from '@frontend/workflow';

import WorkflowButtonElement from '../workflow-buttons/workflow-button-element/workflow-button-element.component';
import WorkflowButtonsGroupElement from '../workflow-buttons/workflow-buttons-group-element/workflow-buttons-group-element.component';
import WorkflowPage from '../workflow-page/workflow-page.component';
import PinWorkflow from '../workflows/pin-workflow/pin-workflow.component';
import useHomePage from './home-page.controller';

const ID = 'home-page';
export interface HomePageProps {}
/**
 * This component should render the workflow buttons
 * if a user authenticates on the terminal the buttons should be refetched
 * once logged out again smiply deleted the user specific buttons
 *      -> this should then in turn show the default buttons again
 *
 *
 * Een interesante manier om navigatie te doen is misschien mbv de store
 * elke workflow heeft zijn eigen workflow state
 * hier kan workflow specifieke navigatie gedaan worden
 *
 * daarboven is er nog een main state waar de hoofdnavigatie gebeurt
 * misshien kunnen de buttons deze state ook gebruiken om een view op te halen ipv dit als parent child mee te geven
 * door de bovenliggende navigatie zo op te slaan kunnen alle elementen deze manipuleren om de navigatie te kunnen gebruiken
 */

const HomePage = (props: HomePageProps) => {
    const viewProps = useHomePage(props);

    if (viewProps.isError)
        return (
            <div id={ID}>
                <h4 className='text-center'>Something went wrong trying to get the correct configuration for this terminal.</h4>
            </div>
        );
    if (viewProps.isLoading)
        return (
            <div id={ID}>
                <Spinner />
            </div>
        );

    if (viewProps.workflow) return <WorkflowPage workflow={viewProps.workflow} />;
    if (viewProps.view)
        return (
            <div>
                <button
                    className='btn btn-info'
                    onClick={viewProps.onHome}>
                    home
                </button>
                {getView(viewProps.view)}
            </div>
        );

    return (
        <div id={ID}>
            <div className='d-flex flex-wrap justify-content-center'>
                {viewProps.buttons[viewProps.buttons.length - 1].map((button) => {
                    if (button.buttons) {
                        return (
                            <WorkflowButtonsGroupElement
                                key={button.id}
                                button={button}
                                onClick={viewProps.addButtonsToStack}
                            />
                        );
                    }
                    return (
                        <WorkflowButtonElement
                            button={button}
                            key={button.id}
                            onClick={viewProps.changeWorkflow}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default HomePage;

//TODO should be exported from somewhere else
function getView(type: WorkflowButtonType): JSX.Element | null {
    switch (type) {
        case WorkflowButtonType.DROPOFF:
            return <PinWorkflow type={[PinCodeType.DROPOFF]} />;
        case WorkflowButtonType.PICKUP:
            return <PinWorkflow type={[PinCodeType.PICKUP]} />;
        case WorkflowButtonType.OPEN_DROPOFF:
        case WorkflowButtonType.MANAGEMENT:
            Logger.error('Workflow not supported yet.', {}, type);
            return null;
        case WorkflowButtonType.UNKNOWN:
        default:
            Logger.error('Unknown workflow button type.', {}, type);
            return null;
    }
}
