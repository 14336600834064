import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { WorkflowClient } from '../api/workflow-client';
import { Workflow, WorkflowListResponse } from '../workflow';

interface WorkflowState {
    unordered: Workflow[];
    workflows: WorkflowListResponse | null;
    workflowsByAccount: { [accountId: string]: WorkflowListResponse } | null;
    status: SliceStatus;
}

const initialState: WorkflowState = {
    unordered: [],
    workflows: null,
    workflowsByAccount: null,
    status: SliceStatus.INIT
};

export const workflowSlice = createSlice({
    name: 'workflows',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkflows.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchWorkflows.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.size) * (toNumber(action.meta.arg.index) - 1);
                if (state.workflows == null) {
                    state.workflows = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.workflows.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.workflows.results.length !== action.payload.count) {
                        state.workflows.count = action.payload.count;
                        state.workflows.results = new Array(action.payload.count);
                    }
                    state.workflows.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((w) => action.payload.results.find((res) => res.id == w.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchWorkflow.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchWorkflow.fulfilled, (state, action) => {
                const found = state.unordered.find((w) => w.id === action.meta.arg.workflowId);
                if (found) {
                    state.unordered[state.unordered.indexOf(found)] = action.payload;
                } else {
                    state.unordered = [...state.unordered, action.payload];
                }
            });
    }
});

export const fetchWorkflows = createAsyncThunk<WorkflowListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchWorkflows',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await WorkflowClient.fetchWorkflows(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchWorkflow = createAsyncThunk<Workflow, { accountId: string; workflowId: string }>(
    'fetchWorkflow',
    async (variables: { accountId: string; workflowId: string }, { rejectWithValue }) => {
        try {
            return await WorkflowClient.fetchAccountWorkflow(variables.accountId, variables.workflowId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);
