import { Workflow, WorkflowButton } from '@frontend/workflow';

import useWorkflowButtonElement from './workflow-button-element.controller';

const ID = 'workflow-button-element';
export interface WorkflowButtonElementProps {
    button: WorkflowButton;
    onClick: (workflow: Workflow) => void;
}

/**
 * Button should
 * get its corresponding workflow -> and initiate it onclick
 * useing its type to render the correct displayname and icon
 *
 * if no workflow is found show the button but make it look disabled
 *
 * if display info is not known do not show the button and log a error about it
 *
 * some buttons will simply have FE functions
 * in that case onNavigate should return the corresponding React component
 */
const WorkflowButtonElement = (props: WorkflowButtonElementProps) => {
    const viewProps = useWorkflowButtonElement(props);

    if (viewProps.displayInfo == null) return <></>;
    return (
        <div
            id={ID}
            className='w-50 ratio ratio-1x1'>
            <div className='p-3'>
                <div
                    className={`card shadow-lg overflow-hidden h-100`}
                    onClick={viewProps.onClick}>
                    <div className='d-flex flex-column h-100'>
                        <div className='d-flex align-items-center justify-content-center h-75 placeholder-glow'>
                            <viewProps.displayInfo.icon
                                size={200}
                                className='text-info'
                            />
                        </div>
                        <div className='d-flex align-items-center justify-content-center h-25 bg-info'>
                            <h4 className='text-white placeholder-glow'>
                                <strong>{viewProps.displayInfo.name}</strong>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkflowButtonElement;
