import { Logger } from '@frontend/Logger';
import { APIClient, ApiViewSet } from '@frontend/api-utils';

export default class ProvisioningClient extends APIClient {
    static endpoint: ApiViewSet = {
        baseName: 'terminal-service/api/v1/provision'
    };
    static async provision(deviceId: string): Promise<any> {
        Logger.debug('Sending provisioning request.');
        const response = await this.post(
            '/' + this.endpoint.baseName,
            {
                identifier: deviceId
            },
            false
        );
        Logger.debug('Provisioning response code: ' + response.status);
        return response.json();
    }
}
