import { WorkflowButton } from '@frontend/workflow';

import { WorkflowButtonOrGroup } from '../../home-page/home-page.controller';
import useWorkflowButtonsGroupElement from './workflow-buttons-group-element.controller';

const ID = 'workflow-buttons-group-element';
export interface WorkflowButtonsGroupElementProps {
    button: WorkflowButtonOrGroup;
    onClick: (buttons: WorkflowButton[]) => void;
}

const WorkflowButtonsGroupElement = (props: WorkflowButtonsGroupElementProps) => {
    const viewProps = useWorkflowButtonsGroupElement(props);

    if (viewProps.displayInfo == null) return <></>;
    return (
        <div
            id={ID}
            className='w-50 ratio ratio-1x1'>
            <div className='p-3'>
                <div
                    className={`card shadow-lg overflow-hidden h-100`}
                    onClick={() => props.onClick(props.button.buttons!)}>
                    <div className='d-flex flex-column h-100'>
                        <div className='d-flex align-items-center justify-content-center h-75'>
                            <viewProps.displayInfo.icon
                                size={200}
                                className='text-info'
                            />
                        </div>
                        <div className='d-flex align-items-center justify-content-center h-25 bg-info'>
                            <h4 className='text-white placeholder-glow'>
                                <strong>{viewProps.displayInfo.name}</strong>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkflowButtonsGroupElement;
