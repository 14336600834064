import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum TriggerName {
    TRANSACTION_STATE_CHANGED = 'transaction_state_changed',
    TRANSACTION_CANCELLED = 'transaction_cancelled',
    REMOVAL_CODE_CREATED = 'removal_code_created',
    DROP_OFF_CODE_SCANNED = 'drop_off_code_scanned',
    DROP_OFF_COMPLETED = 'drop_off_completed',
    PICK_UP_CODE_SCANNED = 'pick_up_code_scanned',
    PICK_UP_COMPLETED = 'pick_up_completed',
    REMOVAL_CODE_SCANNED = 'removal_code_scanned',
    REMOVAL_COMPLETED = 'removal_completed',
    WAREHOUSE_ITEMS_CONFIRMED = 'warehouse_items_confirmed'
}

export class WorkflowStepTrigger {
    static readonly TRANSACTION_STATE_CHANGED = new WorkflowStepTrigger(
        TriggerName.TRANSACTION_STATE_CHANGED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.transaction_state_changed'
                description='The displayed value for the WorkflowStepTrigger transaction_state_changed'
                defaultMessage='Transaction state changed'
            />
        )
    );
    static readonly TRANSACTION_CANCELLED = new WorkflowStepTrigger(
        TriggerName.TRANSACTION_CANCELLED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.transaction_cancelled'
                description='The displayed value for the WorkflowStepTrigger transaction_cancelled'
                defaultMessage='Transaction cancelled'
            />
        )
    );
    static readonly REMOVAL_CODE_CREATED = new WorkflowStepTrigger(
        TriggerName.REMOVAL_CODE_CREATED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.removal_code_created'
                description='The displayed value for the WorkflowStepTrigger removal_code_created'
                defaultMessage='Removal code created'
            />
        )
    );
    static readonly DROP_OFF_CODE_SCANNED = new WorkflowStepTrigger(
        TriggerName.DROP_OFF_CODE_SCANNED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.drop_off_code_scanned'
                description='The displayed value for the WorkflowStepTrigger drop_off_code_scanned'
                defaultMessage='DropOff code scanned'
            />
        )
    );
    static readonly DROP_OFF_COMPLETED = new WorkflowStepTrigger(
        TriggerName.DROP_OFF_COMPLETED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.drop_off_completed'
                description='The displayed value for the WorkflowStepTrigger drop_off_completed'
                defaultMessage='DropOff completed'
            />
        )
    );
    static readonly PICK_UP_CODE_SCANNED = new WorkflowStepTrigger(
        TriggerName.PICK_UP_CODE_SCANNED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.pick_up_code_scanned'
                description='The displayed value for the WorkflowStepTrigger pick_up_code_scanned'
                defaultMessage='PickUp code scanned'
            />
        )
    );
    static readonly PICK_UP_COMPLETED = new WorkflowStepTrigger(
        TriggerName.PICK_UP_COMPLETED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.pick_up_completed'
                description='The displayed value for the WorkflowStepTrigger pick_up_completed'
                defaultMessage='PickUp completed'
            />
        )
    );
    static readonly REMOVAL_CODE_SCANNED = new WorkflowStepTrigger(
        TriggerName.REMOVAL_CODE_SCANNED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.removal_code_scanned'
                description='The displayed value for the WorkflowStepTrigger removal_code_scanned'
                defaultMessage='Removal code scanned'
            />
        )
    );
    static readonly REMOVAL_COMPLETED = new WorkflowStepTrigger(
        TriggerName.REMOVAL_COMPLETED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.removal_completed'
                description='The displayed value for the WorkflowStepTrigger removal_completed'
                defaultMessage='Removal complete'
            />
        )
    );
    static readonly WAREHOUSE_ITEMS_CONFIRMED = new WorkflowStepTrigger(
        TriggerName.WAREHOUSE_ITEMS_CONFIRMED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.warehouse_items_confirmed'
                description='The displayed value for the WorkflowStepTrigger warehouse_items_confirmed'
                defaultMessage='Warehouse items confirmed'
            />
        )
    );

    static readonly ALL = [
        WorkflowStepTrigger.DROP_OFF_CODE_SCANNED,
        WorkflowStepTrigger.DROP_OFF_COMPLETED,
        WorkflowStepTrigger.PICK_UP_CODE_SCANNED,
        WorkflowStepTrigger.PICK_UP_COMPLETED,
        WorkflowStepTrigger.REMOVAL_CODE_CREATED,
        WorkflowStepTrigger.REMOVAL_CODE_SCANNED,
        WorkflowStepTrigger.REMOVAL_COMPLETED,
        WorkflowStepTrigger.TRANSACTION_CANCELLED,
        WorkflowStepTrigger.TRANSACTION_STATE_CHANGED,
        WorkflowStepTrigger.WAREHOUSE_ITEMS_CONFIRMED
    ];

    private constructor(public readonly value: TriggerName, public readonly displayName: React.ReactNode) {}

    static getByValue(action: TriggerName) {
        this.ALL.find((a) => a.value == action);
    }
}
