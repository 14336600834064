import { Spinner } from '@frontend/elements';
import { BsBackspaceFill, BsCheckCircle } from 'react-icons/bs';

import usePinView from './pin-view.controller';

const ID = 'pin-view';
export interface PinViewProps {}

const PinView = (props: PinViewProps) => {
    const viewProps = usePinView(props);

    if (viewProps.lockedOutTime > 0)
        return (
            <div className='text-center'>
                <h3>To many failed attempts. You have been locked out for {viewProps.lockedOutTime} seconds.</h3>
            </div>
        );

    return (
        <div
            id={ID}
            className='h-100'>
            <form
                onSubmit={viewProps.onSubmit}
                className='d-flex flex-column h-100 p-5'>
                <div className='d-flex flex-column justify-content-center h-25'>
                    <div className='text-center mb-2'>
                        <h2>Enter PIN</h2>
                    </div>
                    <div className='d-flex justify-content-center'>
                        {Array.from({ length: viewProps.maxPinLength }).map((_, index) => (
                            <div
                                className='form-group'
                                key={index}>
                                <input
                                    type='password'
                                    className='pin-input'
                                    value={viewProps.pinValues[index] || ''}
                                    disabled={true}
                                />
                            </div>
                        ))}
                    </div>
                    <p className='text-center text-danger display-6 mt-4'>
                        {viewProps.loading ? <Spinner /> : viewProps.invalidCode && <>Invalid pin code provided.</>}
                    </p>
                </div>
                <div className='d-flex flex-column h-75'>
                    <div className='d-flex flex-column justify-content-center'>
                        {Array.from({ length: 3 }).map((_, rowIndex) => {
                            return (
                                <div
                                    key={rowIndex}
                                    className='d-flex justify-content-around pb-5'>
                                    {Array.from({ length: 3 }).map((_, index) => (
                                        <button
                                            key={index + 1}
                                            type='button'
                                            onClick={() => {
                                                viewProps.onInput((index + 1 + 3 * rowIndex).toString());
                                            }}
                                            disabled={!viewProps.pinInputEnabled}
                                            className='pin-button'>
                                            {index + 1 + 3 * rowIndex}
                                        </button>
                                    ))}
                                </div>
                            );
                        })}
                    </div>

                    <div className='row pt-4'>
                        <div className='d-flex justify-content-around'>
                            <button
                                type='button'
                                onClick={viewProps.onBackSpace}
                                disabled={viewProps.pinValues.length === 0}
                                className='pin-button pin-button-backspace'>
                                <BsBackspaceFill />
                            </button>
                            <button
                                type='button'
                                onClick={() => {
                                    viewProps.onInput('0');
                                }}
                                disabled={!viewProps.pinInputEnabled}
                                className='pin-button'>
                                0
                            </button>
                            <button
                                type='submit'
                                className='pin-button pin-button-submit'
                                disabled={viewProps.pinInputEnabled || viewProps.loading}>
                                <BsCheckCircle />
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PinView;
