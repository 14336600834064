declare global {
    interface Window {
        TerminalApp?: HardwareAPI;
    }

    const chrome: {
        webview: {
            addEventListener: (arg0: string, arg1: (event: { data: string }) => void) => void;
            hostObjects: {
                TerminalApp?: HardwareAPI;
                slotObj?: any;
            } | null;
        };
    };
}

//wrapping interface for the HardwareAPI
export interface HardwareConnection {
    getTerminalSoftware(): HardwareAPIType;
    getTerminalSoftwareVersion(): number;
    init(): void;
    setConfiguration(config: any): void;
    sendSpotLayoutConfig(spotLayoutItems: any[]): void;
    reset(): void;
    openSlot(object: any[] | null): Promise<void>;
    openSlotRange(first: string, last: string): Promise<void>;
    setWebUrl(url: string): void;

    keepAlive(): void;
    resetPcb(): void;
    setLights(options: { enableMinorLights?: boolean }): void;
    setFan(on: boolean): void;
    resetCache(): void;
    resetData(): void;

    //Sending over the WS server
    broadcast(data: any): void;
    send(id: string, data: any): void;
}

//Actual API interface for the hardware
export interface HardwareAPI {
    init(): void;

    //try filter functions here
    resetSystem(): void;
    openSlot(object: any): void;
    openSlotRange(fist: string, last: string): Promise<void>;
    keepAlive(): void;
    resetPCB(): void;
    minorLightOn(): void;
    minorLightOff(): void;
    getOccupySlot(slotObj: string): void;
    getOccupySlot(slotObj1: string, slotObj2: string): void;
    getInfoSlot(slotObj: string): void;
    getInfoSlot(slotObj1: string, slotObj2: string): void;
    getStatus(): Promise<{ result_code: number }>;
    resetCache(): void;
    resetData(): void;
}

export enum HardwareAPIType {
    WINDOWS = 'windows',
    ANDROID = 'android',
    WEBSOCKET = 'websocket',
    UNKNOWN = 'unknown'
}
