import { Logger } from '@frontend/Logger';
import { SliceStatus } from '@frontend/common';
import { useEffect, useState } from 'react';

import { checkPinCode } from '../../../redux/pin-workflow-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { PinAttempt } from './pin-attempt';
import { PinViewProps } from './pin-view.component';

//a variable that should be retrieved from the backend
const pinLenght = 4;
interface ViewProps {
    invalidCode: boolean;
    loading: boolean;
    maxPinLength: number;
    pinValues: string[];
    lockedOutTime: number;
    pinInputEnabled: boolean;
    onInput: (value: string) => void;
    onSubmit: (e: any) => void;
    onBackSpace: () => void;
}

const usePinView = (props: PinViewProps): ViewProps => {
    const dispatch = useAppDispatch();
    const pinWorkflow = useAppSelector((state) => state.pinWorkflow);
    const [lockedOutTime, changeLockedOutTime] = useState<number>(0);
    const [pin, changePin] = useState('');

    useEffect(() => {
        pinCheck(changeLockedOutTime, pinWorkflow.pinAttempts);
    }, [pinWorkflow.pinAttempts]);

    const onInput = (value: string) => {
        changePin(pin + value);
    };

    const onBackSpace = () => {
        if (pin.length > 0) {
            changePin(pin.slice(0, -1));
        }
    };
    const onSubmit = (e: any) => {
        e.preventDefault();
        Logger.log('Pin code entered: ', {}, pin);
        dispatch(checkPinCode(pin)).then(() => {
            changePin('');
        });
    };

    return {
        invalidCode: pinWorkflow.pinCodes !== null && pinWorkflow.pinCodes.length === 0,
        loading: pinWorkflow.status === SliceStatus.LOADING,
        maxPinLength: pinLenght,
        pinValues: pin.split(''),
        pinInputEnabled: pin.length < pinLenght,
        lockedOutTime,
        onInput,
        onSubmit,
        onBackSpace
    };
};

export default usePinView;

function pinCheck(changeLockedOutTime: React.Dispatch<React.SetStateAction<number>>, pinState: PinAttempt[]) {
    const time = enterPinAllowed(pinState);
    changeLockedOutTime(Math.round(time));
    if (time > 0) {
        setTimeout(() => {
            pinCheck(changeLockedOutTime, pinState);
        }, 1000);
    }
}

function enterPinAllowed(attempts: PinAttempt[]): number {
    if (attempts.length > 2) {
        const time = attempts[2].timestamp - (Date.now() - 60 * 1000);
        if (time > 0) {
            return time / 1000;
        }
    }
    return 0;
}
