import { useMemo } from 'react';
import { IconType } from 'react-icons';

import { getDisplayInfo } from '../utils';
import { WorkflowButtonsGroupElementProps } from './workflow-buttons-group-element.component';

interface ViewProps {
    displayInfo: {
        icon: IconType;
        name: string | JSX.Element;
    } | null;
}

const useWorkflowButtonsGroupElement = (props: WorkflowButtonsGroupElementProps): ViewProps => {
    const displayInfo = useMemo(() => getDisplayInfo(props.button.type), [props.button.type]);
    return { displayInfo };
};

export default useWorkflowButtonsGroupElement;
