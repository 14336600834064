import { useMemo } from 'react';
import { IconType } from 'react-icons';

import { changeView } from '../../redux/navigation-slice';
import { useAppDispatch } from '../../redux/store';
import { getDisplayInfo } from '../utils';
import { WorkflowButtonElementProps } from './workflow-button-element.component';

interface ViewProps {
    onClick: () => void;
    displayInfo: {
        icon: IconType;
        name: string | JSX.Element;
    } | null;
}

const useWorkflowButtonElement = (props: WorkflowButtonElementProps): ViewProps => {
    const dispatch = useAppDispatch();
    const displayInfo = useMemo(() => getDisplayInfo(props.button.type), [props.button.type]);

    const onClick = () => {
        dispatch(changeView(props.button.type));
    };

    return {
        onClick,
        displayInfo
    };
};

export default useWorkflowButtonElement;
