import { Logger } from '@frontend/Logger';
import { DeviceAuthenticationManager } from '@frontend/device-provisioning';
import { SpotClient } from '@frontend/spot';
import { useEffect, useState } from 'react';

import { Props, ProvisionState } from './provision.component';

interface ViewProps {
    state: ProvisionState;
    message: string;
}

const useProvision = (props: Props): ViewProps => {
    const [authenticationManager] = useState<DeviceAuthenticationManager>(DeviceAuthenticationManager.getInstance());
    const [state, changeState] = useState<ProvisionState>(ProvisionState.INIT);
    const [message, changeMessage] = useState<string>('Authenticating');

    useEffect(() => {
        switch (state) {
            case ProvisionState.AUTHENTICATING:
                changeMessage('Authenticating');
                break;
            case ProvisionState.PERMISSION_CHECK:
                changeMessage('Provision check');
                break;
            case ProvisionState.PERMISSION_CHECK_FAILED:
                changeMessage('This device has not been granted any permissions yet.');
                break;
            case ProvisionState.DONE:
                changeMessage('Provisioning done - Redirecting');
                break;
        }
    }, [state]);

    useEffect(() => {
        switch (state) {
            case ProvisionState.INIT:
                if (!authenticationManager.loggedIn) changeState(ProvisionState.AUTHENTICATING);
                else changeState(ProvisionState.PERMISSION_CHECK);
                break;
            case ProvisionState.AUTHENTICATING:
                authenticationManager.authenticate(props.deviceId).then(() => changeState(ProvisionState.PERMISSION_CHECK));
                break;
            case ProvisionState.PERMISSION_CHECK:
                SpotClient.fetchSpots({})
                    .then((res) => {
                        Logger.log(res);
                        changeState(ProvisionState.DONE);
                    })
                    .catch((err) => {
                        Logger.warn(err);
                        changeState(ProvisionState.PERMISSION_CHECK_FAILED);
                    });
                break;
            case ProvisionState.PERMISSION_CHECK_FAILED:
                setRetry(() => changeState(ProvisionState.PERMISSION_CHECK));
                break;
            case ProvisionState.DONE:
                props.provisionDone && props.provisionDone();
                break;
        }
    }, [state]);

    return { state, message };
};

export default useProvision;

function setRetry(callback: () => void) {
    const retry: NodeJS.Timeout = setTimeout(() => {
        callback();
        return clearTimeout(retry);
    }, 60000);
}
