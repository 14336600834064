import { Spinner } from '@frontend/elements';

import useHardwareConnection from './hardware-connection.controller';

export const ID = 'hardware-connection';
export interface Props {
    callback: (hardwareId: string) => void;
}

const HardwareConnection = (props: Props) => {
    useHardwareConnection(props);
    return (
        <div id={ID}>
            <div className='text-center m-4'>
                <h4>Initiating hardware connection</h4>
                <Spinner />
            </div>
        </div>
    );
};

export default HardwareConnection;
