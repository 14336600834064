import { Spinner } from '@frontend/elements';
import { PinCodeType } from '@frontend/transaction';

import OpenSlotView from './open-slot-view/open-slot-view.component';
import PinView from './pin-view/pin-view.component';
import usePinWorkflow, { PinWorkflowState } from './pin-workflow.controller';

const ID = 'pin-workflow';
export interface PinWorkflowProps {
    type: PinCodeType[];
}

/**
 * pin vragen
 * als meer dan 1 pin vraag voor challenge om te kunnen filteren
 * vanaf 1 pin trx id en spot id gebruiken voor een slot op te vragen
 * gewoon kijken wat voor code het is en de gelinkte transactie beginnen updaten
 * eerst dropoff started zetten
 * slot dan openen (momenteel sowieso een mock functie maar moet ook nog lokaal gebeuren achteraf pas remote opendoor)
 * dan als kastje toe is dropoff done zetten
 *
 *
 * dus
 * gewoon ervanuit gaan dat het slot ok is -> moet geen andere gekozen worden
 * maar kan wel de available slots al opvragen en moet rekening houden met dat het slot ooit aangepast kan worden
 */

const PinWorkflow = (props: PinWorkflowProps) => {
    const viewProps = usePinWorkflow(props);

    if (viewProps.pinWorkflowState === PinWorkflowState.ENTER_PIN) return <PinView />;
    if (viewProps.pinWorkflowState === PinWorkflowState.ENTER_CHALLENGE)
        return <>ask for challenge and update pincodes until only one is left (not implemented yet)</>;
    if (viewProps.pinWorkflowState === PinWorkflowState.OPEN_SLOT) return <OpenSlotView />;
    if (viewProps.pinWorkflowState === PinWorkflowState.DONE) return <>done - redirect to homepage</>;

    return (
        <div id={ID}>
            <Spinner />
        </div>
    );
};

export default PinWorkflow;
