export enum TerminalEvents {
    PROTON = 'terminal:Proton',
    SCANNER_RESULT = 'terminal:Scanner',
    OPEN_SLOT_RANGE = 'terminal:OpenSlotRange',
    OPEN_SLOT = 'terminal:OpenSlot',
    HARDWARE_ID_RESULT = 'terminal:HardwareId'
}

declare global {
    interface WindowEventMap {
        'terminal:initResult': CustomEvent<{ appVersion: string; androidId: string }>;
        'terminal:keepAliveResult': CustomEvent<void>;
        'terminal:getInfoSlotResult': CustomEvent<any>;
        'terminal:getOccupySlotResult': CustomEvent<any>;
        'terminal:scannerResult': CustomEvent<{ scannerValue: string }>;
        'terminal:scannerResultQR': CustomEvent<{ scannerValue: string }>;
        'terminal:scannerResultRFID': CustomEvent<{ scannerValue: string }>;
        'terminal:openSlotResult': CustomEvent<void>;
        'terminal:proton': CustomEvent<{ proton: unknown }>;
        'terminal:slotsStateUpdateResult': CustomEvent<any>;
        'terminal:externalOAuthResponse': CustomEvent<{ url: string }>;

        //internal events (thrown and caught by the terminal itself)
        'terminal:Proton': CustomEvent<any>;
        'terminal:Scanner': CustomEvent<any>;
        'terminal:OpenSlotRange': CustomEvent<any>;
        'terminal:OpenSlot': CustomEvent<any>;
        'terminal:HardwareId': CustomEvent<{ hardwareId: string }>;
    }
}
