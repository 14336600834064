import { FEATURES } from '@frontend/feature-flags';
import { ToastUtil } from '@frontend/toast-utils';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { AccessToken, AuthResponse } from './models';

export async function loginRequest(email: string, password: string): Promise<AccessToken> {
    const response = await fetch(process.env['NX_TOKEN_URL']!, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            grant_type: 'password',
            username: `${email}`,
            password: `${password}`,
            client_id: `${process.env['NX_CLIENT_ID']}`,
            client_secret: `${process.env['NX_CLIENT_SECRET']}`,
            skip_authorization: 'true',
            ...(FEATURES.pubSub && (process.env['NX_CLIENT_SCOPE'] ? { scope: process.env['NX_CLIENT_SCOPE'] } : { scope: 'read write pubsub' }))
        })
    });
    return new Promise((resolve, reject) => {
        if (response.status === 200) {
            response.json().then((data: AuthResponse) => {
                resolve({
                    accessToken: data.access_token,
                    refreshToken: data.refresh_token,
                    expires: new Date(new Date().getTime() + data.expires_in * 1000),
                    tokenType: data.token_type
                });
            });
        } else {
            response.json().then((data) => {
                if ('error' in data) {
                    ToastUtil.error(
                        'Error',
                        <FormattedMessage
                            id='login.failed.message'
                            description='Message displayed when the login failed'
                            defaultMessage='Invalid credentials given.'
                        />
                    );
                }
            });
            reject();
        }
    });
}

export async function refreshRequest(refreshToken: string): Promise<AccessToken> {
    const response = await fetch(process.env['NX_TOKEN_URL']!, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            grant_type: 'refresh_token',
            client_id: `${process.env['NX_CLIENT_ID']}`,
            client_secret: `${process.env['NX_CLIENT_SECRET']}`,
            refresh_token: `${refreshToken}`
        })
    });
    return new Promise((resolve, reject) => {
        if (response.status === 200) {
            response.json().then((data: AuthResponse) => {
                resolve({
                    accessToken: data.access_token,
                    refreshToken: data.refresh_token,
                    expires: new Date(new Date().getTime() + data.expires_in * 1000),
                    tokenType: data.token_type
                });
            });
        } else {
            reject();
        }
    });
}
