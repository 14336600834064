import HomePage from './home-page/home-page.component';
import Initialisation from './initialisation/initialisation.component';

export function App() {
    return (
        <div className='app-content'>
            <Initialisation>
                <HomePage />
            </Initialisation>
        </div>
    );
}

export default App;
