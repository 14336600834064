import { Logger } from '@frontend/Logger';
import { useEffect } from 'react';

import { HardwareConnectionManager } from './hardware-connection-manager';
import { Props } from './hardware-connection.component';
import { TerminalEvents } from './hardware-events';

interface ViewProps {}

const useHardwareConnection = (props: Props): ViewProps => {
    useEffect(() => {
        waitForHardwareId(props.callback);
        HardwareConnectionManager.getInstance().getConnection();
    }, []);
    return {};
};

export default useHardwareConnection;

async function waitForHardwareId(callback: (val: string) => void): Promise<void> {
    try {
        const result: string = await new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                reject();
            }, 30000);
            window.addEventListener(TerminalEvents.HARDWARE_ID_RESULT, (event: CustomEvent) => {
                resolve(event.detail.hardwareId as string);
                clearTimeout(timeout);
            });
        });

        callback(result);
    } catch (error) {
        Logger.error('Waiting for hardware id timed out.(took more then 30 seconds)');
    }
}
