import useConfirmSlotView from './confirm-slot-view.controller';

const ID = 'confirm-slot-view';
export interface ConfirmSlotViewProps {}

/**
 * currently should just have a button to open the slot
 */

const ConfirmSlotView = (props: ConfirmSlotViewProps) => {
    const viewProps = useConfirmSlotView(props);

    return (
        <div id={ID}>
            <button
                className='btn btn-primary'
                onClick={viewProps.onSubmit}>
                confirm
            </button>
        </div>
    );
};

export default ConfirmSlotView;
