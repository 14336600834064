import { Logger } from '@frontend/Logger';
import { WorkflowButtonType } from '@frontend/workflow';
import { IconType } from 'react-icons';
import { BsBoxArrowInDown, BsBoxArrowUp, BsShop } from 'react-icons/bs';

export function getDisplayInfo(type: WorkflowButtonType): { icon: IconType; name: string | JSX.Element } | null {
    switch (type) {
        case WorkflowButtonType.DROPOFF:
            return {
                name: 'DropOff',
                icon: BsBoxArrowInDown
            };
        case WorkflowButtonType.PICKUP:
            return {
                name: 'PickUp',
                icon: BsBoxArrowUp
            };
        case WorkflowButtonType.OPEN_DROPOFF:
            return {
                name: 'Open DropOff',
                icon: BsBoxArrowInDown
            };
        case WorkflowButtonType.MANAGEMENT:
            return {
                name: 'Management',
                icon: BsShop
            };
        case WorkflowButtonType.UNKNOWN:
        default:
            Logger.error('Unknown workflow button type.', {}, type);
            return null;
    }
}
