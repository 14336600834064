export interface AuthenticationSuccessResponse {
    entity_type: string;
    entity_id: string;
    context: {
        entity_type: string;
        entity_id: string;
    } | null;
    jwt_token: string;
    refresh_token: string;
}

export class AuthenticationError extends Error {
    detail: string;

    constructor(message: string, response: { detail: string }) {
        super(message);
        this.detail = response.detail;
    }
}
