import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { Account, AccountListResponse, CreateAccount, SearchAccountQueryParams, UpdateAccount } from '../account';

const endpoint = '/account-service/api/v1';
export class AccountClient extends APIClient {
    public static async fetchAccounts(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<AccountListResponse> {
        return await this.apiPaginated<AccountListResponse, DefaultQueryParams>(`${endpoint}/accounts`, queryParams);
    }

    public static async postAccount(account: CreateAccount): Promise<Account> {
        const response = await this.post(`${endpoint}/accounts`, account);
        return await this.handleResponse<Account>(response);
    }

    public static async resolveAccounts(accountIds: string[]): Promise<AccountListResponse> {
        const response = await this.post(`${endpoint}/accounts-resolve`, accountIds);
        return await this.handleResponse<AccountListResponse>(response);
    }

    public static async searchAccount(queryParams?: ApiQueryParams<DefaultQueryParams | SearchAccountQueryParams>): Promise<AccountListResponse> {
        return await this.apiPaginated<AccountListResponse, DefaultQueryParams | SearchAccountQueryParams>(`${endpoint}/accounts-search`, queryParams);
    }

    public static async fetchAccount(accountId: string): Promise<Account> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}`);
        return await this.handleResponse<Account>(response);
    }

    public static async patchAccount(account: UpdateAccount, accountId: string): Promise<Account> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}`, account);
        return await this.handleResponse<Account>(response);
    }

    public static async deleteAccount(accountId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}`);
        return await this.handleResponse(response);
    }
}
