import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { CreatePinCode, PinCode, PinCodeListResponse, PinCodeQueryParams, SearchPinCodeQueryParams } from '../pin-code';

const endpoint = '/transaction-service/api/v1';
export class PinCodeClient extends APIClient {
    public static async fetchPinCodes(queryParams?: ApiQueryParams<DefaultQueryParams | PinCodeQueryParams>): Promise<PinCodeListResponse> {
        return await this.apiPaginated<PinCodeListResponse, DefaultQueryParams | PinCodeQueryParams>(`${endpoint}/pin-codes`, queryParams);
    }

    public static async postPinCode(pinCode: CreatePinCode): Promise<PinCode> {
        const response = await this.post(`${endpoint}/pin-codes`, pinCode);
        return await this.handleResponse<PinCode>(response);
    }

    public static async resolvePinCodes(pinCodeIds: string[]): Promise<PinCodeListResponse> {
        const response = await this.post(`${endpoint}/pin-codes-resolve`, pinCodeIds);
        return await this.handleResponse<PinCodeListResponse>(response);
    }

    public static async searchPinCode(queryParams?: ApiQueryParams<DefaultQueryParams | SearchPinCodeQueryParams>): Promise<PinCodeListResponse> {
        return await this.apiPaginated<PinCodeListResponse, DefaultQueryParams | SearchPinCodeQueryParams>(`${endpoint}/pin-codes-search`, queryParams);
    }

    public static async fetchPinCode(pinCodeId: string): Promise<PinCode> {
        const response = await this.fetch(`${endpoint}/pin-codes/${pinCodeId}`);
        return await this.handleResponse<PinCode>(response);
    }

    public static async deletePinCode(pinCodeId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/pin-codes/${pinCodeId}`);
        return await this.handleResponse(response);
    }
}
