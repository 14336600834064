import { TransactionState } from '@frontend/transaction';
import { useEffect, useState } from 'react';

import { changeView } from '../../redux/navigation-slice';
import { addPinType, clearWorkflow, startTransaction } from '../../redux/pin-workflow-slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { PinWorkflowProps } from './pin-workflow.component';

interface ViewProps {
    pinWorkflowState: PinWorkflowState;
}

export enum PinWorkflowState {
    INIT = 'init',
    LOADING = 'loading',
    ENTER_PIN = 'enter_pin',
    ENTER_CHALLENGE = 'enter_challenge',
    OPEN_SLOT = 'open_slot',
    DONE = 'done'
}

const usePinWorkflow = (props: PinWorkflowProps): ViewProps => {
    const dispatch = useAppDispatch();
    const pinWorkflow = useAppSelector((state) => state.pinWorkflow);
    const [pinWorkflowState, changePinWorkflowState] = useState<PinWorkflowState>(PinWorkflowState.INIT);

    useEffect(() => {
        if (pinWorkflow.transaction !== null && (pinWorkflowState === PinWorkflowState.OPEN_SLOT || pinWorkflowState === PinWorkflowState.LOADING)) {
            if (TransactionState.isDone(pinWorkflow.transaction.state)) {
                changePinWorkflowState(PinWorkflowState.DONE);
                dispatch(changeView(null));
                dispatch(clearWorkflow());
            } else {
                changePinWorkflowState(PinWorkflowState.OPEN_SLOT);
            }
            return;
        }
        if (pinWorkflow.pinCodes !== null && pinWorkflow.pinCodes.length > 0) {
            if (pinWorkflow.pinCodes.length === 1) {
                dispatch(startTransaction(pinWorkflow.pinCodes[0]));
                changePinWorkflowState(PinWorkflowState.LOADING);
            } else {
                changePinWorkflowState(PinWorkflowState.ENTER_CHALLENGE);
            }
            return;
        }
        if (pinWorkflow.type) {
            changePinWorkflowState(PinWorkflowState.ENTER_PIN);
            return;
        }
        if (pinWorkflowState === PinWorkflowState.INIT) {
            dispatch(addPinType(props.type));
            return;
        }
    }, [pinWorkflow]);

    return {
        pinWorkflowState
    };
};

export default usePinWorkflow;
