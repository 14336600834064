import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { SlotClient } from '../api/slot-client';
import { Slot, SlotListResponse } from '../slot';

interface SlotState {
    unordered: Slot[];
    slots: SlotListResponse | null;
    spotSlots: { [spotId: string]: SlotListResponse } | null;
    status: SliceStatus;
}

const initialState: SlotState = {
    unordered: [],
    slots: null,
    spotSlots: null,
    status: SliceStatus.INIT
};

export const slotSlice = createSlice({
    name: 'slots',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSlots.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSlots.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.size) * (toNumber(action.meta.arg.index) - 1);
                if (state.slots == null) {
                    state.slots = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.slots.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.slots.results.length !== action.payload.count) {
                        state.slots.count = action.payload.count;
                        state.slots.results = new Array(action.payload.count);
                    }
                    state.slots.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(fetchSpotSlots.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSpotSlots.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.queryParams.size) * (toNumber(action.meta.arg.queryParams.index) - 1);
                if (state.spotSlots == null) {
                    state.spotSlots = { [action.meta.arg.spotId]: { ...action.payload, results: new Array(action.payload.count) } };
                    state.spotSlots[action.meta.arg.spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.spotSlots[action.meta.arg.spotId].results.length !== action.payload.count) {
                        state.spotSlots[action.meta.arg.spotId].count = action.payload.count;
                        state.spotSlots[action.meta.arg.spotId].results = new Array(action.payload.count);
                    }
                    state.spotSlots[action.meta.arg.spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(deleteSlot.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteSlot.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                if (state.slots) {
                    const found = state.slots.results.find((s) => s.id == action.meta.arg.slotId);
                    if (found == undefined) return;
                    state.slots.results.splice(state.slots.results.indexOf(found), 1);
                }
                if (state.spotSlots && state.spotSlots[action.meta.arg.spotId]) {
                    const found = state.spotSlots[action.meta.arg.spotId].results.find((s) => s.id == action.meta.arg.slotId);
                    if (found == undefined) return;
                    state.spotSlots[action.meta.arg.spotId].results.splice(state.spotSlots[action.meta.arg.spotId].results.indexOf(found), 1);
                }
            })
            .addCase(fetchSpotSlot.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSpotSlot.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                if (state.spotSlots && state.spotSlots[action.meta.arg.spotId]) {
                    const found = state.spotSlots[action.meta.arg.spotId].results.find((s) => s.id == action.meta.arg.slotId);
                    if (found == undefined) {
                        state.spotSlots[action.meta.arg.spotId].results.push(action.payload);
                    } else {
                        state.spotSlots[action.meta.arg.spotId].results.splice(
                            state.spotSlots[action.meta.arg.spotId].results.indexOf(found),
                            1,
                            action.payload
                        );
                    }
                } else {
                    state.spotSlots = { [action.meta.arg.spotId]: { count: 1, results: [action.payload] } };
                }
                state.unordered = [...state.unordered.filter((s) => action.payload.id != s.id), action.payload];
            });
    }
});

export const fetchSlots = createAsyncThunk<SlotListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchSlots',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await SlotClient.fetchSlots(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchSpotSlots = createAsyncThunk<SlotListResponse, { spotId: string; queryParams: ApiQueryParams<DefaultQueryParams> }>(
    'fetchSpotSlots',
    async (variables: { spotId: string; queryParams: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await SlotClient.fetchSpotSlots(variables.spotId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteSlot = createAsyncThunk<void, { spotId: string; slotId: string }>(
    'deleteSpot',
    async (variables: { spotId: string; slotId: string }, { rejectWithValue }) => {
        try {
            return await SlotClient.deleteSpotSlot(variables.spotId, variables.slotId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchSpotSlot = createAsyncThunk<Slot, { spotId: string; slotId: string }>(
    'fetchSpotSlot',
    async (variables: { spotId: string; slotId: string }, { rejectWithValue }) => {
        try {
            return await SlotClient.fetchSpotSlot(variables.spotId, variables.slotId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);
