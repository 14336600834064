import { HardwareAPIType, HardwareConnection } from '../hardware-connection';
import { AndroidEventListener } from './android-event-listener';
import AndroidHardwareAPI from './android-hardware-api';

export class AndroidConnectionDriver implements HardwareConnection {
    private android: AndroidHardwareAPI;

    constructor(android: AndroidHardwareAPI) {
        this.android = android;
        new AndroidEventListener(this);
        this.init();
    }
    setWebUrl(url: string): void {
        this.android.setWebUrl(url);
    }
    getTerminalSoftware(): HardwareAPIType {
        throw new Error('Method not implemented.');
    }
    getTerminalSoftwareVersion(): number {
        throw new Error('Method not implemented.');
    }
    init(): void {
        this.android.init();
    }
    setConfiguration(config: any): void {
        throw new Error('Method not implemented.');
    }
    sendSpotLayoutConfig(spotLayoutItems: any[]): void {
        throw new Error('Method not implemented.');
    }
    reset(): void {
        throw new Error('Method not implemented.');
    }
    openSlot(object: any[] | null): Promise<void> {
        throw new Error('Method not implemented.');
    }
    openSlotRange(first: string, last: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    keepAlive(): void {
        throw new Error('Method not implemented.');
    }
    resetPcb(): void {
        throw new Error('Method not implemented.');
    }
    setLights(options: { enableMinorLights?: boolean | undefined }): void {
        throw new Error('Method not implemented.');
    }
    setFan(on: boolean): void {
        throw new Error('Method not implemented.');
    }
    resetCache(): void {
        throw new Error('Method not implemented.');
    }
    resetData(): void {
        throw new Error('Method not implemented.');
    }
    broadcast(data: any): void {
        throw new Error('Method not implemented.');
    }
    send(id: string, data: any): void {
        throw new Error('Method not implemented.');
    }
}
