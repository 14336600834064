import { WorkflowStep, fetchIncompleteAccountWorkflowSteps } from '@frontend/workflow';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../redux/store';
import { WorkflowPageProps } from './workflow-page.component';

interface ViewProps {}

const useWorkflowPage = (props: WorkflowPageProps): ViewProps => {
    const dispatch = useAppDispatch();
    const workflowStepsState = useAppSelector((state) => state.workflowSteps);
    const [steps, changeSteps] = useState<WorkflowStep[] | null>(null);
    useEffect(() => {
        if (steps == null) {
            dispatch(
                fetchIncompleteAccountWorkflowSteps({
                    accountId: props.workflow.account_id,
                    workflowId: props.workflow.id
                })
            );
        }
        const wfSteps = workflowStepsState.incompleteStepsByAccountAndWorkflow?.[props.workflow.account_id + ':' + props.workflow.id];
        if (wfSteps) changeSteps(wfSteps);
    }, [workflowStepsState.incompleteStepsByAccountAndWorkflow]);

    return {};
};

export default useWorkflowPage;
