import { PaginatedResponse } from '@frontend/api-utils';

/**
 * @param code (matches ^[0-9])
 */
export interface PinCode {
    id: string;
    type: PinCodeType;
    code: string;
    challenge: string | null;
    spot_id: string;
    transaction_id: string;
    creation_timestamp: string;
}

export type PinCodeListResponse = PaginatedResponse<PinCode>;

export interface CreatePinCode {
    type?: PinCodeType;
    code: string;
    challenge?: string | null;
}

export enum PinCodeQueryParams {
    CODE = 'code'
}

export enum SearchPinCodeQueryParams {
    PARTIAL_TEXT = 'partial_text'
}

export enum PinCodeType {
    DROPOFF = 'drop_off',
    PICKUP = 'pick_up',
    REMOVE = 'remove',
    RENT = 'rent'
}
