import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { SpotClient } from '../api/spots-client';
import { Spot, SpotListResponse } from '../spot';

interface SpotState {
    unordered: Spot[];
    spots: SpotListResponse | null;
    status: SliceStatus;
}

const initialState: SpotState = {
    unordered: [],
    spots: null,
    status: SliceStatus.INIT
};

export const spotSlice = createSlice({
    name: 'spots',
    initialState,
    reducers: {
        seedSpots(state, action: PayloadAction<Spot[]>) {
            state.unordered = [...state.unordered.filter((spot) => action.payload.find((s) => s.id == spot.id) == undefined), ...action.payload];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSpots.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSpots.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.size) * (toNumber(action.meta.arg.index) - 1);
                if (state.spots == null) {
                    state.spots = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.spots.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.spots.results.length !== action.payload.count) {
                        state.spots.count = action.payload.count;
                        state.spots.results = new Array(action.payload.count);
                    }
                    state.spots.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((spot) => action.payload.results.find((a) => a.id == spot.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchSpot.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSpot.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                if (state.spots) {
                    const found = state.spots.results.find((s) => s.id == action.meta.arg);
                    if (found) {
                        state.spots.results.splice(state.spots.results.indexOf(found), 1, action.payload);
                    } else {
                        state.spots.results.push(action.payload);
                    }
                } else {
                    state.spots = { count: 1, results: [action.payload] };
                }
                state.unordered = [...state.unordered.filter((spot) => action.payload.id !== spot.id), action.payload];
            });
    }
});

export const fetchSpots = createAsyncThunk<SpotListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchSpots',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await SpotClient.fetchSpotsPaginated(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchSpot = createAsyncThunk<Spot, string>('fetchSpot', async (spotId: string, { rejectWithValue }) => {
    try {
        return await SpotClient.fetchSpot(spotId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const { seedSpots } = spotSlice.actions;
