import { useEffect, useState } from 'react';

import HardwareConnection from './hardware-connection/hardware-connection.component';
import useInitialisation from './initialisation.controller';
import Provision from './provision/provision.component';

export enum InitialisationState {
    INIT,
    HARDWARE_INIT,
    PROVISION,
    DONE
}

export const ID = 'initialisation';
interface Props {
    children: React.ReactNode;
}

const Initialisation = (props: Props) => {
    const viewProps = useInitialisation();
    const [content, changeContent] = useState<React.ReactNode>(<></>);

    useEffect(() => {
        switch (viewProps.state) {
            case InitialisationState.INIT:
                changeContent(<div>loading</div>);
                break;
            case InitialisationState.HARDWARE_INIT:
                changeContent(<HardwareConnection callback={viewProps.hardwareConnectionDoneCallback} />);
                break;
            case InitialisationState.PROVISION:
                changeContent(
                    <Provision
                        deviceId={viewProps.deviceId!}
                        provisionDone={viewProps.provisionDoneCallback}
                    />
                );
                break;
            case InitialisationState.DONE:
                changeContent(props.children);
                break;
        }
    }, [viewProps.state]);

    return (
        <div
            id={ID}
            className='h-100'>
            {content}
        </div>
    );
};

export default Initialisation;
