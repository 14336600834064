import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { CreateSlot, SearchSlotQueryParams, Slot, SlotListResponse, UpdateSlot } from '../slot';

const endpoint = '/slot-service/api/v1';

export class SlotClient extends APIClient {
    public static async fetchSlots(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<SlotListResponse> {
        return await this.apiPaginated<SlotListResponse, DefaultQueryParams>(`${endpoint}/slots`, queryParams);
    }

    public static async searchSlots(queryParams?: ApiQueryParams<DefaultQueryParams | SearchSlotQueryParams>): Promise<SlotListResponse> {
        return await this.apiPaginated<SlotListResponse, DefaultQueryParams | SearchSlotQueryParams>(`${endpoint}/slots-search`, queryParams);
    }

    public static async fetchSpotSlots(spotId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<SlotListResponse> {
        return await this.apiPaginated<SlotListResponse, DefaultQueryParams>(`${endpoint}/spots/${spotId}/slots`, queryParams);
    }

    public static async postSpotSlot(spotId: string, slot: CreateSlot): Promise<Slot> {
        const response = await this.post(`${endpoint}/spots/${spotId}/slots`, slot);
        return await this.handleResponse<Slot>(response);
    }

    public static async fetchSpotSlot(spotId: string, slotId: string): Promise<Slot> {
        const response = await this.fetch(`${endpoint}/spots/${spotId}/slots/${slotId}`);
        return await this.handleResponse<Slot>(response);
    }

    public static async patchSpotSlot(spotId: string, slotId: string, body: UpdateSlot): Promise<Slot> {
        const response = await this.patch(`${endpoint}/spots/${spotId}/slots/${slotId}`, body);
        return await this.handleResponse<Slot>(response);
    }

    public static async deleteSpotSlot(spotId: string, slotId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/spots/${spotId}/slots/${slotId}`);
        return await this.handleVoidResponse(response);
    }
}
