import { APIClient } from '@frontend/api-utils';

import { WorkflowActionFieldTemplate } from '../workflow-action-field-template';

const endpoint = '/workflow-service/api/v1';
export class WorkflowActionTemplateClient extends APIClient {
    public static async fetchWorkflowActionFieldTemplates(): Promise<{ [key: string]: WorkflowActionFieldTemplate[] }> {
        const response = await this.fetch(`${endpoint}/workflow-action-field-templates`);
        return await this.handleResponse<{ [key: string]: any[] }>(response);
    }
}
