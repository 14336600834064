import { Logger } from '@frontend/Logger';
import { ApiError } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { Slot, SlotListResponse, SlotTransactionClient } from '@frontend/slot';
import { PinCode, PinCodeClient, PinCodeListResponse, PinCodeType, Transaction, TransactionState, TransactionWorkflowClient } from '@frontend/transaction';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PinAttempt } from '../workflows/pin-workflow/pin-view/pin-attempt';

/**
 * we only need to store the last 3 pin attempts because attempts will also be recorded by the BE
 * they also dont need to be stored in persistent storage because a power outage will take longer to recover then simply waiting for the failed pin attempts to expire
 * so simply check for 3 failed attempts in the last minute
 * then lock for 1 minute
 * after that just remove all attempts
 */

interface PinWorkflowState {
    //pin view
    type: PinCodeType[] | null;
    pinAttempts: PinAttempt[];
    pinCodes: PinCode[] | null;

    transaction: Transaction | null;
    slots: Slot[] | null;
    slotsConfirmed: boolean | null;

    status: SliceStatus;
}

const initialState: PinWorkflowState = {
    type: null,
    pinAttempts: [],
    pinCodes: null,

    transaction: null,
    slots: null,
    slotsConfirmed: null,

    status: SliceStatus.INIT
};

export const pinWorkflowSlice = createSlice({
    name: 'pinWorkflow',
    initialState,
    reducers: {
        addPinType: (state, action: PayloadAction<PinCodeType[]>) => {
            state.type = action.payload;
            state.status = SliceStatus.IDLE;
        },
        confirmSlots: (state) => {
            state.slotsConfirmed = true;
        },
        changeSlots: (state) => {
            state.slotsConfirmed = false;
        },
        clearWorkflow: (state) => {
            state.type = initialState.type;
            state.pinAttempts = initialState.pinAttempts;
            state.pinCodes = initialState.pinCodes;
            state.transaction = initialState.transaction;
            state.slots = initialState.slots;
            state.slotsConfirmed = initialState.slotsConfirmed;
            state.status = initialState.status;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkPinCode.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(checkPinCode.fulfilled, (state, action) => {
                const pin = action.meta.arg;
                const result = action.payload.results.filter((r) => state.type!.includes(r.type));
                if (result.length === 0) {
                    if (state.pinAttempts.length >= 3) state.pinAttempts.shift();
                    state.pinAttempts = [...state.pinAttempts, { success: false, timestamp: Date.now(), pin }];
                    Logger.log('Invalid pin code attempt: ' + pin);
                    state.pinCodes = [];
                } else {
                    state.pinAttempts = [{ success: true, timestamp: Date.now(), pin }];
                    Logger.log('Pin ' + pin + ' was recognized as a valid pin code.');
                    state.pinCodes = result;
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(startTransaction.fulfilled, (state, action) => {
                state.transaction = action.payload;
            })
            .addCase(fetchTransactionSlots.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchTransactionSlots.fulfilled, (state, action) => {
                state.slots = action.payload.results;
                state.status = SliceStatus.IDLE;
            })
            .addCase(endTransaction.fulfilled, (state, action) => {
                state.transaction = action.payload;
            });
    }
});

export const { addPinType, confirmSlots, changeSlots, clearWorkflow } = pinWorkflowSlice.actions;

export const checkPinCode = createAsyncThunk<PinCodeListResponse, string>('pinWorkflow:checkPinCode', async (code: string, { rejectWithValue }) => {
    try {
        return await PinCodeClient.fetchPinCodes({ code: code });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const startTransaction = createAsyncThunk<Transaction, PinCode>('pinWorkflow:startTransaction', async (pin: PinCode, { rejectWithValue }) => {
    try {
        return await TransactionWorkflowClient.updateSpotTransactionState(pin.spot_id, pin.transaction_id, TransactionState.getStartingState(pin.type).value);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const endTransaction = createAsyncThunk<Transaction, PinCode>('pinWorkflow:endTransaction', async (pin: PinCode, { rejectWithValue }) => {
    try {
        return await TransactionWorkflowClient.updateSpotTransactionState(pin.spot_id, pin.transaction_id, TransactionState.getEndingState(pin.type).value);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchTransactionSlots = createAsyncThunk<SlotListResponse, PinCode>(
    'pinWorkflow:fetchTransactionSlots',
    async (pin: PinCode, { rejectWithValue }) => {
        try {
            return await SlotTransactionClient.fetchSpotTransactionSlots(pin.spot_id, pin.transaction_id);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);
