import { useEffect, useState } from 'react';

import { InitialisationState } from './initialisation.component';

interface ViewProps {
    state: InitialisationState;
    hardwareConnectionDoneCallback: (deviceId: string) => void;
    provisionDoneCallback: () => void;
    deviceId?: string;
}

const useInitialisation = (): ViewProps => {
    const [deviceId, changeDeviceId] = useState<string | undefined>(undefined);
    const [state, changeState] = useState<InitialisationState>(InitialisationState.INIT);

    useEffect(() => {
        switch (state) {
            case InitialisationState.INIT:
                changeState(InitialisationState.HARDWARE_INIT);
                break;
            case InitialisationState.HARDWARE_INIT:
                if (deviceId !== undefined) changeState(InitialisationState.PROVISION);
                break;
        }
    }, [state, deviceId]);

    return {
        state,
        hardwareConnectionDoneCallback: changeDeviceId,
        provisionDoneCallback: () => changeState(InitialisationState.DONE),
        deviceId
    };
};

export default useInitialisation;
