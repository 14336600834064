import { HardwareAPIType, HardwareConnection } from '../hardware-connection';
import WindowsHardwareAPI from './windows-hardware-api';

export class WindowsConnectionDriver implements HardwareConnection {
    private windows: WindowsHardwareAPI;

    constructor(windows: WindowsHardwareAPI) {
        this.windows = windows;
        this.init();
    }
    setWebUrl(url: string): void {
        throw new Error('Method not implemented.');
    }

    getTerminalSoftware(): HardwareAPIType {
        throw new Error('Method not implemented.');
    }
    getTerminalSoftwareVersion(): number {
        throw new Error('Method not implemented.');
    }
    init(): void {
        throw new Error('Method not implemented.');
    }
    setConfiguration(config: any): void {
        throw new Error('Method not implemented.');
    }
    sendSpotLayoutConfig(spotLayoutItems: any[]): void {
        throw new Error('Method not implemented.');
    }
    reset(): void {
        throw new Error('Method not implemented.');
    }
    openSlot(object: any[] | null): Promise<void> {
        throw new Error('Method not implemented.');
    }
    openSlotRange(first: string, last: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    keepAlive(): void {
        throw new Error('Method not implemented.');
    }
    resetPcb(): void {
        throw new Error('Method not implemented.');
    }
    setLights(options: { enableMinorLights?: boolean | undefined }): void {
        throw new Error('Method not implemented.');
    }
    setFan(on: boolean): void {
        throw new Error('Method not implemented.');
    }
    resetCache(): void {
        throw new Error('Method not implemented.');
    }
    resetData(): void {
        throw new Error('Method not implemented.');
    }
    broadcast(data: any): void {
        throw new Error('Method not implemented.');
    }
    send(id: string, data: any): void {
        throw new Error('Method not implemented.');
    }
}
