import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { WorkflowStepClient } from '../api/workflow-step-client';
import { WorkflowStep, WorkflowStepListResponse } from '../workflow-step';

interface WorkflowStepState {
    unordered: WorkflowStep[];
    workflowStepsByAccountAndWorkflow: { [accAndWorkId: string]: WorkflowStepListResponse } | null;
    incompleteStepsByAccountAndWorkflow: { [accAndWorkId: string]: WorkflowStep[] } | null;
    status: SliceStatus;
}

const initialState: WorkflowStepState = {
    unordered: [],
    workflowStepsByAccountAndWorkflow: null,
    incompleteStepsByAccountAndWorkflow: null,
    status: SliceStatus.INIT
};

export const workflowStepSlice = createSlice({
    name: 'workflowSteps',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccountWorkflowSteps.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccountWorkflowSteps.fulfilled, (state, action) => {
                if (action.meta.arg.queryParams.size == null || action.meta.arg.queryParams.index == null) return;
                const listId = action.meta.arg.accountId + ':' + action.meta.arg.workflowId;
                const startPos = toNumber(action.meta.arg.queryParams.size) * (toNumber(action.meta.arg.queryParams.index) - 1);
                if (state.workflowStepsByAccountAndWorkflow == null) state.workflowStepsByAccountAndWorkflow = {};
                if (state.workflowStepsByAccountAndWorkflow[listId] == undefined) {
                    state.workflowStepsByAccountAndWorkflow[listId] = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.workflowStepsByAccountAndWorkflow[listId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.workflowStepsByAccountAndWorkflow[listId].results.length !== action.payload.count) {
                        state.workflowStepsByAccountAndWorkflow[listId].count = action.payload.count;
                        state.workflowStepsByAccountAndWorkflow[listId].results = new Array(action.payload.count);
                    }
                    state.workflowStepsByAccountAndWorkflow[listId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((w) => action.payload.results.find((res) => res.id == w.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchIncompleteAccountWorkflowSteps.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchIncompleteAccountWorkflowSteps.fulfilled, (state, action) => {
                const listId = action.meta.arg.accountId + ':' + action.meta.arg.workflowId;
                if (state.incompleteStepsByAccountAndWorkflow == null) state.incompleteStepsByAccountAndWorkflow = {};
                state.incompleteStepsByAccountAndWorkflow[listId] = action.payload;
                state.status = SliceStatus.IDLE;
            });
    }
});

export const fetchAccountWorkflowSteps = createAsyncThunk<
    WorkflowStepListResponse,
    { accountId: string; workflowId: string; queryParams: ApiQueryParams<DefaultQueryParams> }
>(
    'fetchAccountWorkflowSteps',
    async (params: { accountId: string; workflowId: string; queryParams: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await WorkflowStepClient.fetchAccountWorkflowSteps(params.accountId, params.workflowId, params.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchIncompleteAccountWorkflowSteps = createAsyncThunk<WorkflowStep[], { accountId: string; workflowId: string }>(
    'fetchIncompleteAccountWorkflowSteps',
    async (params: { accountId: string; workflowId: string }, { rejectWithValue }) => {
        try {
            return await WorkflowStepClient.fetchIncompleteWorkflowSteps(params.accountId, params.workflowId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);
