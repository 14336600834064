import { SliceStatus } from '@frontend/common';
import { Workflow, WorkflowButton, WorkflowButtonType, fetchUserWorkflowButtons } from '@frontend/workflow';
import { useEffect, useState } from 'react';

import { changeView } from '../redux/navigation-slice';
import { clearWorkflow } from '../redux/pin-workflow-slice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { HomePageProps } from './home-page.component';

interface ViewProps {
    //TODO remove this onHome (it is temporary)
    onHome: () => void;

    isError: boolean;
    isLoading: boolean;
    buttons: WorkflowButtonOrGroup[][];
    workflow: Workflow | null;
    view: WorkflowButtonType | null;
    changeWorkflow: React.Dispatch<React.SetStateAction<Workflow | null>>;
    addButtonsToStack: (elements: WorkflowButtonOrGroup[]) => void;
    removeButtonsFromStack: () => void;
}

export interface WorkflowButtonOrGroup extends WorkflowButton {
    buttons?: WorkflowButton[];
}

const useHomePage = (props: HomePageProps): ViewProps => {
    const dispatch = useAppDispatch();
    const workflowButtonState = useAppSelector((state) => state.workflowButtons);
    const navigationState = useAppSelector((state) => state.navigation);
    const [workflow, changeWorkflow] = useState<Workflow | null>(null);
    const [buttons, changeButtons] = useState<WorkflowButtonOrGroup[][]>([]);

    useEffect(() => {
        if (workflowButtonState.userWorkflowButtons === null && workflowButtonState.status !== SliceStatus.ERROR) dispatch(fetchUserWorkflowButtons({}));
        if (workflowButtonState.userWorkflowButtons) changeButtons([mapButtons(workflowButtonState.userWorkflowButtons.results)]);
    }, [workflowButtonState]);

    const addButtonsToStack = (elements: WorkflowButtonOrGroup[]): void => {
        changeButtons([...buttons, elements]);
    };
    const removeButtonsFromStack = (): void => {
        changeButtons([...buttons.slice(0, -1)]);
    };

    /**
     * should always clear all workflow states
     */
    const onHome = (): void => {
        dispatch(changeView(null));
        dispatch(clearWorkflow());
    };

    return {
        onHome,

        isError: workflowButtonState.status === SliceStatus.ERROR,
        isLoading: workflowButtonState.userWorkflowButtons === null || buttons.length === 0,
        buttons,
        workflow,
        view: navigationState.view,
        changeWorkflow,
        addButtonsToStack,
        removeButtonsFromStack
    };
};

export default useHomePage;

function mapButtons(buttons: WorkflowButton[]): WorkflowButtonOrGroup[] {
    const groups: WorkflowButtonOrGroup[] = [];
    const rootButtons: WorkflowButton[] = [];
    buttons.forEach((button) => {
        if (button.group != undefined) {
            const foundGroup = groups.find((g) => g.type === button.group);
            if (foundGroup) {
                if (foundGroup.buttons) foundGroup.buttons.push(button);
                else foundGroup.buttons = [button];
            } else groups.push({ ...button, buttons: [button], group: undefined, type: button.group });
        } else rootButtons.push(button);
    });
    return [...groups, ...rootButtons];
}
