import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PinCodeType } from './pin-code';

export enum TransactionStateName {
    UNKNOWN = '',
    TRANSACTION_CREATED = 'transaction_created',
    TRANSACTION_STARTED = 'transaction_started',
    DROP_OFF_PENDING = 'drop_off_pending',
    DROP_OFF_STARTED = 'drop_off_started',
    DROP_OFF_DONE = 'drop_off_done',
    PICK_UP_PENDING = 'pick_up_pending',
    PICK_UP_STARTED = 'pick_up_started',
    PICK_UP_DONE = 'pick_up_done',
    PICK_UP_CANCELLED = 'pick_up_cancelled',
    REMOVAL_PENDING = 'removal_pending',
    REMOVAL_STARTED = 'removal_started',
    REMOVAL_DONE = 'removal_done',
    REMOVAL_COMPLETED = 'removal_completed',
    TRANSACTION_CANCELLED = 'transaction_cancelled',
    TRANSACTION_COMPLETED = 'transaction_completed',
    WAREHOUSE_FILLING_PENDING = 'warehouse_filling_pending',
    WAREHOUSE_FILLING_DONE = 'warehouse_filling_done',
    WAREHOUSE_FILLING_COMPLETED = 'warehouse_filling_completed'
}

export class TransactionState {
    static readonly TRANSACTION_CREATED = new TransactionState(
        TransactionStateName.TRANSACTION_CREATED,
        (
            <FormattedMessage
                id='TransactionState.created'
                description='The displayed value for the TransactionState created'
                defaultMessage='Transaction created'
            />
        )
    );
    static readonly TRANSACTION_STARTED = new TransactionState(
        TransactionStateName.TRANSACTION_STARTED,
        (
            <FormattedMessage
                id='TransactionState.transaction_started'
                description='The displayed value for the TransactionState transaction_started'
                defaultMessage='Transaction started'
            />
        )
    );
    static readonly DROP_OFF_PENDING = new TransactionState(
        TransactionStateName.DROP_OFF_PENDING,
        (
            <FormattedMessage
                id='TransactionState.drop_off_pending'
                description='The displayed value for the TransactionState drop_off_pending'
                defaultMessage='DropOff pending'
            />
        )
    );
    static readonly DROP_OFF_STARTED = new TransactionState(
        TransactionStateName.DROP_OFF_STARTED,
        (
            <FormattedMessage
                id='TransactionState.drop_off_started'
                description='The displayed value for the TransactionState drop_off_started'
                defaultMessage='DropOff started'
            />
        )
    );
    static readonly DROP_OFF_DONE = new TransactionState(
        TransactionStateName.DROP_OFF_DONE,
        (
            <FormattedMessage
                id='TransactionState.drop_off_done'
                description='The displayed value for the TransactionState drop_off_done'
                defaultMessage='DropOff done'
            />
        )
    );
    static readonly PICK_UP_PENDING = new TransactionState(
        TransactionStateName.PICK_UP_PENDING,
        (
            <FormattedMessage
                id='TransactionState.pick_up_pending'
                description='The displayed value for the TransactionState pick_up_pending'
                defaultMessage='PickUp pending'
            />
        )
    );
    static readonly PICK_UP_STARTED = new TransactionState(
        TransactionStateName.PICK_UP_STARTED,
        (
            <FormattedMessage
                id='TransactionState.pick_up_started'
                description='The displayed value for the TransactionState pick_up_started'
                defaultMessage='PickUp started'
            />
        )
    );
    static readonly PICK_UP_DONE = new TransactionState(
        TransactionStateName.PICK_UP_DONE,
        (
            <FormattedMessage
                id='TransactionState.pick_up_done'
                description='The displayed value for the TransactionState pick_up_done'
                defaultMessage='PickUp done'
            />
        )
    );
    static readonly PICK_UP_CANCELLED = new TransactionState(
        TransactionStateName.PICK_UP_CANCELLED,
        (
            <FormattedMessage
                id='TransactionState.pick_up_cancelled'
                description='The displayed value for the TransactionState pick_up_cancelled'
                defaultMessage='PickUp cancelled'
            />
        )
    );
    static readonly REMOVAL_PENDING = new TransactionState(
        TransactionStateName.REMOVAL_PENDING,
        (
            <FormattedMessage
                id='TransactionState.removal_pending'
                description='The displayed value for the TransactionState removal_pending'
                defaultMessage='Removal pending'
            />
        )
    );
    static readonly REMOVAL_STARTED = new TransactionState(
        TransactionStateName.REMOVAL_STARTED,
        (
            <FormattedMessage
                id='TransactionState.removal_started'
                description='The displayed value for the TransactionState removal_started'
                defaultMessage='Removal started'
            />
        )
    );
    static readonly REMOVAL_DONE = new TransactionState(
        TransactionStateName.REMOVAL_DONE,
        (
            <FormattedMessage
                id='TransactionState.removal_done'
                description='The displayed value for the TransactionState removal_done'
                defaultMessage='Removal done'
            />
        )
    );
    static readonly REMOVAL_COMPLETED = new TransactionState(
        TransactionStateName.REMOVAL_COMPLETED,
        (
            <FormattedMessage
                id='TransactionState.removal_completed'
                description='The displayed value for the TransactionState removal_completed'
                defaultMessage='Removal completed'
            />
        )
    );
    static readonly TRANSACTION_CANCELLED = new TransactionState(
        TransactionStateName.TRANSACTION_CANCELLED,
        (
            <FormattedMessage
                id='TransactionState.transaction_cancelled'
                description='The displayed value for the TransactionState transaction_cancelled'
                defaultMessage='Transaction cancelled'
            />
        )
    );
    static readonly TRANSACTION_COMPLETED = new TransactionState(
        TransactionStateName.TRANSACTION_COMPLETED,
        (
            <FormattedMessage
                id='TransactionState.transaction_completed'
                description='The displayed value for the TransactionState transaction_completed'
                defaultMessage='Transaction completed'
            />
        )
    );
    static readonly WAREHOUSE_FILLING_PENDING = new TransactionState(
        TransactionStateName.WAREHOUSE_FILLING_PENDING,
        (
            <FormattedMessage
                id='TransactionState.warehouse_filling_pending'
                description='The displayed value for the TransactionState warehouse_filling_pending'
                defaultMessage='Warehouse filling pending'
            />
        )
    );
    static readonly WAREHOUSE_FILLING_DONE = new TransactionState(
        TransactionStateName.WAREHOUSE_FILLING_DONE,
        (
            <FormattedMessage
                id='TransactionState.warehouse_filling_done'
                description='The displayed value for the TransactionState warehouse_filling_done'
                defaultMessage='Warehouse filling done'
            />
        )
    );
    static readonly WAREHOUSE_FILLING_COMPLETED = new TransactionState(
        TransactionStateName.WAREHOUSE_FILLING_COMPLETED,
        (
            <FormattedMessage
                id='TransactionState.warehouse_filling_completed'
                description='The displayed value for the TransactionState warehouse_filling_completed'
                defaultMessage='Warehouse filling completed'
            />
        )
    );

    static readonly ALL = [
        TransactionState.TRANSACTION_CREATED,
        TransactionState.TRANSACTION_STARTED,
        TransactionState.DROP_OFF_DONE,
        TransactionState.DROP_OFF_PENDING,
        TransactionState.DROP_OFF_STARTED,
        TransactionState.PICK_UP_PENDING,
        TransactionState.PICK_UP_STARTED,
        TransactionState.PICK_UP_DONE,
        TransactionState.PICK_UP_CANCELLED,
        TransactionState.REMOVAL_PENDING,
        TransactionState.REMOVAL_STARTED,
        TransactionState.REMOVAL_DONE,
        TransactionState.REMOVAL_COMPLETED,
        TransactionState.TRANSACTION_CANCELLED,
        TransactionState.TRANSACTION_COMPLETED,
        TransactionState.WAREHOUSE_FILLING_PENDING,
        TransactionState.WAREHOUSE_FILLING_DONE,
        TransactionState.WAREHOUSE_FILLING_COMPLETED
    ];

    private constructor(public readonly value: TransactionStateName, public readonly displayName: React.ReactNode) {}

    static getByValue(action: TransactionStateName): TransactionState | undefined {
        return this.ALL.find((a) => a.value == action);
    }

    static getStartingState(type: PinCodeType): TransactionState {
        switch (type) {
            case PinCodeType.DROPOFF:
                return TransactionState.DROP_OFF_STARTED;
            case PinCodeType.PICKUP:
                return TransactionState.PICK_UP_STARTED;
            case PinCodeType.REMOVE:
                return TransactionState.REMOVAL_STARTED;
        }
        throw new Error('Unknown pin code type.');
    }

    static getEndingState(type: PinCodeType): TransactionState {
        switch (type) {
            case PinCodeType.DROPOFF:
                return TransactionState.DROP_OFF_DONE;
            case PinCodeType.PICKUP:
                return TransactionState.PICK_UP_DONE;
            case PinCodeType.REMOVE:
                return TransactionState.REMOVAL_DONE;
        }
        throw new Error('Unknown pin code type.');
    }

    static isDone(state: TransactionStateName): boolean {
        return state === TransactionStateName.DROP_OFF_DONE || state === TransactionStateName.PICK_UP_DONE || state === TransactionStateName.REMOVAL_DONE;
    }
}
