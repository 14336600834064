import { useMemo } from 'react';

import { WorkflowStepTrigger } from '../../workflow-step-triggers';

interface ViewProps {
    options: { value: string; label: React.ReactNode }[];
}

const useWorkflowStepTriggerSelect = (): ViewProps => {
    const options = useMemo(() => {
        return WorkflowStepTrigger.ALL.map((trigger) => {
            return {
                value: trigger.value,
                label: trigger.displayName
            };
        });
    }, []);

    return { options };
};

export default useWorkflowStepTriggerSelect;
