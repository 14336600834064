import { Spinner } from '@frontend/elements';

import ConfirmSlotView from './confirm-slot-view/confirm-slot-view.component';
import useOpenSlotView, { OpenSlotViewState } from './open-slot-view.controller';

const ID = 'open-slot-view';
export interface OpenSlotViewProps {}

const OpenSlotView = (props: OpenSlotViewProps) => {
    const viewProps = useOpenSlotView(props);

    if (viewProps.state === OpenSlotViewState.CONFIRM_SLOT) return <ConfirmSlotView />;
    if (viewProps.state === OpenSlotViewState.OPENING_SLOT) return <>opening slot</>;
    if (viewProps.state === OpenSlotViewState.SLOT_OPENED) return <>Your slot is now open</>;
    if (viewProps.state === OpenSlotViewState.SLOT_CLOSED) return <>Your slot is now closed</>;

    return (
        <div id={ID}>
            <Spinner />
        </div>
    );
};

export default OpenSlotView;
