import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { SlotListResponse } from '../slot';

const endpoint = '/slot-service/api/v1';

export class SlotTransactionClient extends APIClient {
    static async fetchSpotTransactionSlots(spotId: string, transactionId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<SlotListResponse> {
        return this.apiPaginated<SlotListResponse, DefaultQueryParams>(`${endpoint}/spots/${spotId}/transactions/${transactionId}/slots`, queryParams);
    }
}
