import { PaginatedResponse } from '@frontend/api-utils';
import { Permission } from '@frontend/authorization';

export interface WorkflowButton {
    id: string;
    display?: string;
    group?: WorkflowButtonType;
    permission: Permission;
    type: WorkflowButtonType;
    account_id: string;
    workflow_id: string;
    creation_timestamp: string;
}

export type WorkflowButtonListResponse = PaginatedResponse<WorkflowButton>;

export enum WorkflowButtonType {
    DROPOFF = 'drop_off',
    PICKUP = 'pick_up',
    OPEN_DROPOFF = 'open_drop_off',
    MANAGEMENT = 'management',
    UNKNOWN = ''
}

export interface CreateWorkflowButton {
    permission: Permission;
    type: WorkflowButtonType;
}

export interface UpdateWorkflowButton {
    permission: Permission;
}
